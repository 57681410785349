import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.css'; // Import the internal CSS file

const ProfilePage = () => {
  const navigate = useNavigate();

  // Fetch user data from local storage
  const userName = localStorage.getItem('userName');
  const userMobileNumber = localStorage.getItem('userMobileNumber');

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('userName');
    localStorage.removeItem('userMobileNumber');
    navigate('/'); // Redirect to login page
  };

  return (
    <div className="container profile-page">
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-md-8 col-lg-6 col-xl-4">
          <div className="card shadow-lg profile-card">
            <div className="card-body">
              <h2 className="text-center mb-4 decoration-underline">Profile</h2>
              <div className="mb-3">
                <h5 className="card-title">Username : 
                <b className="card-text ms-3 text-primary">{userName || 'Not available'}</b></h5>
              </div>
              <div className="mb-3">
                <h5 className="card-title">Mobile  :  
                 <b className="card-text ms-3 text-primary">{userMobileNumber || 'Not available'}</b></h5>
              </div>
              <div className="text-center mt-4">
                <button className="btn btn-primary profile-logout-btn" onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};

export default ProfilePage;
