import React from 'react';

const AlertPage = ({ isOpen, onConfirm, onCancel, message }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modalContent}>
        <h4>Confirmation</h4>
        <p>{message}</p>
        <div style={styles.modalFooter}>
          <button onClick={onConfirm} style={styles.confirmButton}>Yes</button>
          <button onClick={onCancel} style={styles.cancelButton}>No</button>
        </div>
      </div>
    </div>
  );
};

// Inline CSS styles
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  confirmButton: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: '#fff',
    margin: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  cancelButton: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#6c757d',
    color: '#fff',
    margin: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default AlertPage;
