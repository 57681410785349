import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Card, Button, Form, Col, Row, Modal } from 'react-bootstrap';
import { db, storage } from '../firebase';
import { collection, addDoc, onSnapshot, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import AlertPage from './Alertpage';
function Newsletter({ isAdmin }) {
  const [news, setNews] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [base64Image, setBase64Image] = useState('');
  const [video, setVideo] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIdToDelete, setCurrentIdToDelete] = useState(null);
 

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'Newsletter'), snapshot => {
      const newsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNews(newsData);
    });

    return () => unsubscribe();
  }, []);

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImage(file);
        setVideo(null);
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (file.type.startsWith('video/')) {
        setVideo(file);
        setImage(null);
        setBase64Image('');
      }
    }
  };

  const handleAddNews = async () => {
    if (!title || !content || (!image && !video)) {
      alert('Please fill all fields and upload an image or video.');
      return;
    }

    setUploading(true);

    try {
      let mediaUrl = '';
      let mediaType = '';

      if (image) {
        const imageName = `${news.length + 1}_${image.name}`;
        const storageRef = ref(storage, `images/${imageName}`);
        await uploadBytes(storageRef, image);
        mediaUrl = await getDownloadURL(storageRef);
        mediaType = 'image';
      } else if (video) {
        const videoName = `${news.length + 1}_${video.name}`;
        const storageRef = ref(storage, `videos/${videoName}`);
        await uploadBytes(storageRef, video);
        mediaUrl = await getDownloadURL(storageRef);
        mediaType = 'video';
      }

      const newsItem = {
        title,
        content,
        media: mediaUrl,
        mediaType: mediaType,
        base64Image,
        timestamp: new Date().toISOString(),
      };

      const docRef = await addDoc(collection(db, 'Newsletter'), newsItem);
      newsItem.id = docRef.id;
      setNews([...news, newsItem]);
      setTitle('');
      setContent('');
      setImage(null);
      setVideo(null);
      setBase64Image('');
    } catch (error) {
      console.error('Error adding document: ', error);
    } finally {
      setUploading(false);
    }
  };

  const handleEditNews = async () => {
    if (!selectedNews) return;

    setUploading(true);

    try {
      const newsRef = doc(db, 'Newsletter', selectedNews.id);
      await updateDoc(newsRef, {
        title,
        content,
        media: selectedNews.media,
        mediaType: selectedNews.mediaType,
        base64Image: selectedNews.base64Image,
        timestamp: new Date().toISOString(),
      });

      const updatedNews = {
        ...selectedNews,
        title,
        content,
        timestamp: new Date().toISOString(),
      };

      const updatedNewsList = news.map(item =>
        item.id === selectedNews.id ? updatedNews : item
      );

      setNews(updatedNewsList);
      setTitle('');
      setContent('');
      setImage(null);
      setVideo(null);
      setBase64Image('');
      setEditMode(false);
    } catch (error) {
      console.error('Error updating document: ', error);
    } finally {
      setUploading(false);
    }
  };

  const openDeleteModal = (newsId) => {
    setCurrentIdToDelete(newsId); // Set the ID of the news item to be deleted
    setIsModalOpen(true); // Open the modal
  };
  const handleDeleteNews = async () => {
    try {
      if (currentIdToDelete) {
        await deleteDoc(doc(db, 'Newsletter', currentIdToDelete));
        console.log('Document deleted successfully!');
        setNews(prevNews => prevNews.filter(item => item.id !== currentIdToDelete));
        setIsModalOpen(false); // Close the modal
        setCurrentIdToDelete(null); // Clear the ID
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      alert('Failed to delete document. Please try again.');
    }
  };
  const handleModalCancel = () => {
    setIsModalOpen(false); // Close the modal
    setCurrentIdToDelete(null); // Clear the ID
  };
  
  // const handleDeleteNews = async newsId => {
  //   if (!window.confirm('Are you sure you want to delete this news item?')) {
  //     return;
  //   }

  //   try {
  //     await deleteDoc(doc(db, 'Newsletter', newsId));
  //     const updatedNewsList = news.filter(item => item.id !== newsId);
  //     setNews(updatedNewsList);
  //   } catch (error) {
  //     console.error('Error deleting document: ', error);
  //   }
  // };

  const handleEdit = newsItem => {
    setSelectedNews(newsItem);
    setTitle(newsItem.title);
    setContent(newsItem.content);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setSelectedNews(null);
    setTitle('');
    setContent('');
    setImage(null);
    setVideo(null);
    setBase64Image('');
  };

  return (
    <div>
      <Header />
      <div className="container mt-4">
        <h3 className='pageHeading'>महत्वपूर्ण माहिती</h3>
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Form className="p-4 shadow-sm rounded border">
              <Form.Group controlId="formTitle">
                <Form.Label>शीर्षक</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="शीर्षक प्रविष्ट करा"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  className='w-100'
                />
              </Form.Group>

              <Form.Group controlId="formContent">
                <Form.Label className='mt-2'>माहिती</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="माहिती प्रविष्ट करा"
                  value={content}
                  onChange={e => setContent(e.target.value)}

                />
              </Form.Group>

              <Form.Group controlId="formMedia" >
                <Form.Label className='mt-2'>फोटो किंवा व्हिडिओ अपलोड करा
</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                />
              </Form.Group>

              <Button
                onClick={editMode ? handleEditNews : handleAddNews}
                disabled={uploading}
                className="mr-2 mt-3 btn-success"
              >
                {uploading ? 'Uploading...' : editMode ? 'Save Changes' : 'Add '}
              </Button>
              {editMode && (
                <Button variant="secondary" onClick={handleCancelEdit}>
                  Cancel
                </Button>
              )}
            </Form>
          </Col>
        </Row>

        <div className="mt-4">
          <Row xs={1} md={3} className="g-4">
            {news.map(newsItem => (
              <Col key={newsItem.id} className="mb-4">
                <Card  className="h-100" style={{ backgroundColor: '#f1f9ec' }}>
                  {newsItem.mediaType === 'image' ? (
                    <Card.Img
                      variant="top"
                      src={newsItem.media} onClick={() => setSelectedCard(newsItem)}
                      className="card-img-top pt-2"
                      style={{
                        width: '300px',
                        height: '250px',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    <video
                      controls
                      className="card-img-top p-1"
                      style={{
                        width: '300px',
                        height: '250px',
                        margin: 'auto',
                      }}
                    >
                      <source src={newsItem.media} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}

                  <Card.Body style={{ backgroundColor: '#f1f9ec' }}>
                    <Card.Title onClick={() => setSelectedCard(newsItem)}>{newsItem.title}</Card.Title>
                    <Card.Text onClick={() => setSelectedCard(newsItem)} style={{ borderBottom: '1px solid gray', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>
                      {newsItem.content}
                    </Card.Text>

                    <Button
                      onClick={() => handleEdit(newsItem)}
                      className="btn-warning p-0 pt-1 px-2"
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </Button>
                    <Button
                      onClick={() =>openDeleteModal(newsItem.id)}
                      className="btn-danger p-0 pt-1 px-2"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Footer />
      <AlertPage
        isOpen={isModalOpen}
        onConfirm={handleDeleteNews}
        onCancel={handleModalCancel}
        message="Are you sure you want to delete this news item?"
        confirmText="Yes, Delete"
        cancelText="Cancel"

      />
      <Footer />
      {/* Modal to display selected news */}
      {selectedCard && (
        <Modal show={!!selectedCard} onHide={() => setSelectedCard(null)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedCard.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedCard.mediaType === 'image' ? (
              <img src={selectedCard.media} alt={selectedCard.title} style={{ maxWidth: '100%', height: 'auto' }} />
            ) : (
              <video controls style={{width:'100%'}}>
                <source src={selectedCard.media} type="video/mp4"  />
                Your browser does not support the video tag.
              </video>
            )}
            <p>{selectedCard.content}</p>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default Newsletter;
