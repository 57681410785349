import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Card, Modal, Button } from "react-bootstrap";
import { db, storage } from "../firebase";
import {
  collection,
  onSnapshot,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

function Newsletter({ isAdmin }) {
  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Newsletter"), (snapshot) => {
      const newsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNews(newsData);
    });

    return () => unsubscribe();
  }, []);

  const handleEdit = (newsItem) => {
    setSelectedNews(newsItem);
  };

  const handleCloseModal = () => {
    setSelectedNews(null);
  };

  const renderMedia = (newsItem) => {
    if (newsItem.mediaType === "image") {
      return (
        <div
          className="image-container pt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card.Img
            variant="top"
            src={newsItem.media}
            className="news-image"
            style={{
              width: "300px",
              height: "250px",
              margin: "auto",
            }}
          />
        </div>
      );
    } else if (newsItem.mediaType === "video") {
      return (
        <div className="embed-responsive embed-responsive-16by9 ">
          <video
            controls
            className="embed-responsive-item w-100 p-1"
            style={{
              width: "300px",
              height: "250px",
              margin: "auto",
            }}
          >
            <source src={newsItem.media} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Header />
      <div className="container mt-4 pb-4">
        <h2 className="pageHeading">महत्वपूर्ण माहिती</h2>
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-4 mb-5">
          {news.map((newsItem) => (
            <div key={newsItem.id} className="col">
              <Card
                className="h-100 shadow-sm"
                style={{ backgroundColor: "#b3e8ff" }}
              >
                <div onClick={() => handleEdit(newsItem)}>
                  {renderMedia(newsItem)}
                </div>
                <hr />
                <Card.Body style={{ backgroundColor: "#b3e8ff" }}>
                  <Card.Title>
                    <b style={{fontSize:'14px'}}>{newsItem.title}</b>
                  </Card.Title>
                  <Card.Text
                    className="news-content"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize:'12px'
                    }}
                  >
                    {newsItem.content}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>

        {selectedNews && (
          <Modal
            show={selectedNews !== null}
            onHide={handleCloseModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{selectedNews?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderMedia(selectedNews)}
              <p>{selectedNews?.content}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                बंद करा
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Newsletter;
