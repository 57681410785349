import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import Header from "./Header";
import Footer from "./Footer";
import "./CreateGat.css";

function CreateGat() {
  const { id } = useParams();
  const [teams, setTeams] = useState([]);
  const [newTeamName, setNewTeamName] = useState("");
  const [editTeamIndex, setEditTeamIndex] = useState(null);
  const [editingMembersForTeam, setEditingMembersForTeam] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const [numberOfInputsToShow, setNumberOfInputsToShow] = useState(10);
  const [selectedMembers, setSelectedMembers] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false); // Declare forceUpdate state
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [filteredTeams, setFilteredTeams] = useState([]); // Add filtered teams state

  const navigate = useNavigate();
  useEffect(() => {
    const fetchTeams = async () => {
      const docRef = doc(db, "agamiMaidane", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setTeams(data.gatKramank || []);
        setPlaceName(data.place || "");
  
        const selectedMembersMap = {};
        if (data.semifinalistList) {
          data.semifinalistList.forEach((item) => {
            if (!selectedMembersMap[item.gatName]) {
              selectedMembersMap[item.gatName] = [];
            }
            selectedMembersMap[item.gatName].push(item.memberName);
          });
        }
        setSelectedMembers(selectedMembersMap);
      } else {
        console.log("No such document!");
      }
    };
  
    fetchTeams();
  }, [id]);
  

  useEffect(() => {
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filtered = teams.filter(
        (team) =>
          team.name.toLowerCase().includes(lowerCaseQuery) ||
          team.members.some((member) =>
            member.toLowerCase().includes(lowerCaseQuery)
          )
      );
      setFilteredTeams(filtered);
    } else {
      setFilteredTeams([]);
    }
  }, [searchQuery, teams]);

  const updateTeamAndMembers = async (updatedTeams) => {
    try {
      await updateDoc(doc(db, "agamiMaidane", id), {
        gatKramank: updatedTeams,
      });
      setTeams(updatedTeams);
      console.log("Data updated successfully in Firestore!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleAddTeam = async () => {
    if (!newTeamName) return;

    const newTeam = { srNo: teams.length + 1, name: newTeamName, members: [] };
    const updatedTeams = [...teams, newTeam];

    await updateTeamAndMembers(updatedTeams);
    setNewTeamName("");
  };

  const handleEditTeamName = async (srNo) => {
    const updatedTeams = teams.map((team) =>
      team.srNo === srNo ? { ...team, name: newTeamName } : team
    );

    await updateTeamAndMembers(updatedTeams);
    setEditTeamIndex(null);
    setNewTeamName("");
  };

  const handleClickTeamName = (srNo) => {
    if (editingMembersForTeam === srNo) {
      setEditingMembersForTeam(null);
    } else {
      setEditingMembersForTeam(srNo);
    }
  };

  const removeMemberFromTeam = async (teamSrNo, index) => {
    const updatedTeams = teams.map((team) => {
      if (team.srNo === teamSrNo) {
        const updatedMembers = [...team.members];
        updatedMembers[index] = "";
        return { ...team, members: updatedMembers };
      }
      return team;
    });
  
    await updateTeamAndMembers(updatedTeams);
  };
  

  const handleAddInput = () => {
    setNumberOfInputsToShow((prev) => prev + 1);
  };

  const handleMemberNameChange = async (teamSrNo, index, value) => {
    const updatedTeams = teams.map((team) => {
      if (team.srNo === teamSrNo) {
        const updatedMembers = [...team.members];
        updatedMembers[index] = value;
        return { ...team, members: updatedMembers };
      }
      return team;
    });
  
    setTeams(updatedTeams);
  
    await updateDoc(doc(db, "agamiMaidane", id), { gatKramank: updatedTeams });
  
    console.log(
      `Member ${index + 1} for team ${teamSrNo} updated successfully in Firestore!`
    );
  };
  

  const handleSelectMember = async (teamSrNo, memberName) => {
    const team = teams.find((team) => team.srNo === teamSrNo);
    const memberIndex = team.members.indexOf(memberName);

    if (memberIndex !== -1) {
      const currentSelectedMembers = selectedMembers[team.name] || [];

      if (currentSelectedMembers.includes(memberName)) {
        await updateDoc(doc(db, "agamiMaidane", id), {
          semifinalistList: arrayRemove({
            memberName,
            gatName: team.name,
            positionNumber: memberIndex + 1,
          }),
        });

        setSelectedMembers((prev) => ({
          ...prev,
          [team.name]: currentSelectedMembers.filter(
            (member) => member !== memberName
          ),
        }));
      } else {
        await updateDoc(doc(db, "agamiMaidane", id), {
          semifinalistList: arrayUnion({
            memberName,
            gatName: team.name,
            positionNumber: memberIndex + 1,
          }),
        });

        setSelectedMembers((prev) => ({
          ...prev,
          [team.name]: [...currentSelectedMembers, memberName],
        }));
      }
    }

    setForceUpdate((prev) => !prev);
  };
  const teamsToDisplay = searchQuery ? filteredTeams : teams;
  const goToSemiFinalList = (id) => {
    navigate(`/create9244-semi9344-final-list/${id}`);
  };
  const deleteTeam = async (teamSrNo) => {
    const updatedTeams = teams.filter((team) => team.srNo !== teamSrNo);
    await updateTeamAndMembers(updatedTeams);
  };

  return (
    <div>
      <Header />
      <div
        className="create-gat-style p-4"
        style={{ fontSize: "12px", width: "100%", margin: "0 auto" }}
      >
        <h1>मैदान : {placeName}</h1>
        <button
          className="show-finale-btn p-1 float-end"
          onClick={() => goToSemiFinalList(id)}
          style={{ fontSize: "12px", marginTop: "14px" }}
        >
          पुढील फेरी <i className="fa-solid fa-arrow-right"></i>
        </button>
        <div className="create-gat-first-btn m-3">
          <input
            type="text"
            value={newTeamName}
            onChange={(e) => setNewTeamName(e.target.value)}
            placeholder="गटाचे नाव प्रविष्ट करा"
          />
          <button onClick={handleAddTeam}>Add</button>
        </div>

        <div className=" pb-4" style={{ width: "100%", overflowX: "auto" }}>
          <div
            className="search-bar-container d-flex align-items-end justify-content-end"
            style={{ position: "relative" }}
          >
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="गटाचे किंवा सदस्यांचे नाव शोधा"
              style={{
                paddingLeft: "20px",
                paddingRight: "5px",
                width: "200px",
              }}
            />
          </div>
          <div className="horizontal-scroll-wrapper table-responsive">
            <table className="table" style={{ maxWidth: "100%" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center", fontSize: "18px" }}>नाव</th>
                  <th style={{ textAlign: "center", fontSize: "18px" }}>
                    सुधारणे
                  </th>
                  <th style={{ textAlign: "center", fontSize: "18px" }}>
                    काढा
                  </th>
                </tr>
              </thead>
              <tbody>
                {teamsToDisplay.map((team, index) => (
                  <tr key={team.srNo}>
                    <td>
                      {editTeamIndex === team.srNo ? (
                        <input
                          type="text"
                          value={newTeamName}
                          onChange={(e) => setNewTeamName(e.target.value)}
                        />
                      ) : (
                        <span
                          style={{
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            height: "100%",
                            color: "green",
                          }}
                          onClick={() => handleClickTeamName(team.srNo)}
                        >
                          {team.name}
                        </span>
                      )}
                      {editingMembersForTeam === team.srNo && (
                        <tr>
                          <td colSpan="3" style={{ verticalAlign: "middle" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {Array.from(
                                { length: numberOfInputsToShow },
                                (_, idx) => (
                                  <div
                                    key={idx}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <span>
                                      <b> तास {idx + 1} : </b>
                                    </span>
                                    <input
                                      type="text"
                                      value={
                                        teams.find((t) => t.srNo === team.srNo)
                                          ?.members[idx] || ""
                                      }
                                      onChange={(e) =>
                                        handleMemberNameChange(
                                          team.srNo,
                                          idx,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        marginRight: "10px",
                                        marginLeft: "5px",
                                        padding: "4px",
                                      }}
                                    />
                                    <button
                                      onClick={() =>
                                        removeMemberFromTeam(team.srNo, idx)
                                      }
                                      style={{
                                        background: "none",
                                        border: "none",
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-xmark"
                                        style={{ color: "black" }}
                                      ></i>
                                    </button>

                                    <button
                                      style={{
                                        backgroundColor: selectedMembers[
                                          team.name
                                        ]?.includes(team.members[idx])
                                          ? "transparent"
                                          : "transparent",
                                        border: "none",
                                        marginLeft: "10px",
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                      disabled={!team.members[idx]}
                                      onClick={() =>
                                        handleSelectMember(
                                          team.srNo,
                                          team.members[idx]
                                        )
                                      }
                                    >
                                      <i
                                        className="fa-regular fa-circle-check rounded-circle"
                                        style={{
                                          color: selectedMembers[
                                            team.name
                                          ]?.includes(team.members[idx])
                                            ? "green"
                                            : "grey",
                                        }}
                                      ></i>
                                    </button>
                                  </div>
                                )
                              )}
                              <button
                                onClick={handleAddInput}
                                style={{
                                  padding: "3px 4px",
                                  paddingTop: "7px",
                                  fontSize: "12px",
                                  width: "100px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  background: "rgb(4, 64, 119)",
                                }}
                              >
                                अधिक सदस्य जोडा +
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </td>
                    <td>
                      {editTeamIndex === team.srNo ? (
                        <button onClick={() => handleEditTeamName(team.srNo)}>
                          जतन करा
                        </button>
                      ) : (
                        <button
                          onClick={() => setEditTeamIndex(team.srNo)}
                          style={{
                            background: "none",
                            border: "none",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{
                              color: "Black",
                              padding: "2px",
                              fontSize: "15px",
                            }}
                          ></i>
                        </button>
                      )}
                    </td>
                    <td>
                      <button
                        onClick={() => deleteTeam(team.srNo)}
                        style={{
                          background: "none",
                          border: "none",
                          fontSize: "14px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          padding: "2px",
                        }}
                      >
                        <i
                          className="fa fa-circle-xmark"
                          style={{
                            color: "red",
                            padding: "2px",
                            fontSize: "15px",
                          }}
                        ></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CreateGat;
