import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Footer from './Footer';
import Header from './Header';
import './ManageGat.css'; // Import custom CSS file

function ManageGat() {
  const { id, gatIndex } = useParams();
  const [gat, setGat] = useState(null);
  const [semiFinalList, setSemiFinalList] = useState([]);

  useEffect(() => {
    const fetchGat = async () => {
      try {
        const docRef = doc(db, 'agamiMaidane', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.semifinaleGat && data.semifinaleGat[gatIndex]) {
            setGat(data.semifinaleGat[gatIndex]);
          } else {
            console.log("No such gat found!");
          }
          if (data.semifinaleList) {
            const assignedMembers = new Set();
            if (data.semifinaleGat) {
              data.semifinaleGat.forEach(gat => {
                gat.members.forEach(member => {
                  assignedMembers.add(member.id);
                });
              });
            }
            const availableMembers = data.semifinaleList.filter(member => !assignedMembers.has(member.id));
            setSemiFinalList(availableMembers);
          } else {
            console.log("No semifinaleList found!");
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching gat:", error);
      }
    };

    fetchGat();
  }, [id, gatIndex]);

  const handleAddSemiFinalMember = async (member) => {
    const updatedGat = { ...gat, members: [...gat.members, member] };

    try {
      const docRef = doc(db, 'agamiMaidane', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        data.semifinaleGat[gatIndex] = updatedGat;

        await updateDoc(docRef, { semifinaleGat: data.semifinaleGat });
        setGat(updatedGat);
        setSemiFinalList(semiFinalList.filter(m => m.id !== member.id));
      }
    } catch (error) {
      console.error("Error adding semifinal member:", error);
    }
  };

  const handleRemoveMember = async (memberId) => {
    const updatedGat = {
      ...gat,
      members: gat.members.filter(member => member.id !== memberId)
    };

    try {
      const docRef = doc(db, 'agamiMaidane', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        data.semifinaleGat[gatIndex] = updatedGat;

        await updateDoc(docRef, { semifinaleGat: data.semifinaleGat });
        setGat(updatedGat);

        // Add member back to semiFinalList
        const removedMember = gat.members.find(member => member.id === memberId);
        if (removedMember) {
          setSemiFinalList([...semiFinalList, removedMember]);
        }
      }
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="container pb-5  text-center">
        {gat ? (
          <>
            <h1 className="my-5 pageHeading">{gat.gatName}</h1>
            <h4 className="my-4 pageHeading2">गटाचे सदस्य</h4>
            {gat.members.length > 0 ? (
              <ul className="list-group mb-4 custom-ul">
                {gat.members.map((member, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center custom-li">
                    {member.name}  - {member.address}
                    <button className="btn btn-danger btn-sm" onClick={() => handleRemoveMember(member.id)}>  <i class="fa-solid fa-trash-can"></i></button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className='text-danger pb-5'> अध्याप {gat.gatName} गटाचे सदस्य निवडले गेले नाहीत. खालील यादी मधून या गटासाठी सदस्य निवडा.  </p>
            )}
            <hr className='mt-5'/>
            <h2 className="my-4 pageHeading2">उत्तीर्ण सदस्य</h2>
            <table className="table table-striped ">
              <thead>
                <tr>
                  <th>नाव</th>
                  <th>पत्ता</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {semiFinalList.map((member, index) => (
                  <tr key={index}>
                    <td>{member.name}</td>
                    <td>{member.address}</td>
                    <td>
                      <button className="btn btn-success btn-sm" onClick={() => handleAddSemiFinalMember(member)}>निवडा</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>Loading gat...</p>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default ManageGat;
