import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { db } from "../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import lg from "../image/lg.jpeg";

function AgamiMaidane() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "agamiMaidane");
      const unsubscribe = onSnapshot(eventsCollection, (snapshot) => {
        const fetchedEvents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(fetchedEvents);
      });

      return unsubscribe;
    };

    fetchEvents();
  }, []);

  const formatTime = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}`);
    return time.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
  };

  const handleCallCommitteeContact = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h3 className="pageHeading">आगमी मैदाने</h3>
        <div className="row">
          {events.map((event, index) => (
            <div key={event.id} className="col-md-4 mb-5">
              <div
                className="card"
                style={{
                  color: "black",
                  backgroundColor: "#89cff0 ",
                  border: "1px solid #ccc",
                  borderRadius: "0.25rem",
                  transition: "transform 0.2s ease-in-out ",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.9)",
                }}
              >
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "1.5rem",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      color: " white",
                      backgroundColor: "#0047ab",
                      padding: "5px",
                    }}
                  >
                    {event.place}
                  </h5>
                  <hr />
                  <table className="table table-striped text-dark mb-0">
                    <tbody>
                      <tr>
                        <td>ठिकाण</td>
                        <td>
                          {event.place}
                          <a
                            href={event.maplocation}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="map-img"
                          >
                            <img
                              src={lg}
                              alt="winner"
                              style={{
                                width: "35px",
                                height: "35px",
                                right: "0",
                                float: "right",
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>समालोचक</td>
                        <td>{event.samalochak}</td>
                      </tr>
                      <tr>
                        <td scope="row">झेंडापंच</td>
                        <td>{event.zendapanch}</td>
                      </tr>
                      <tr>
                        <td scope="row">अंतर</td>
                        <td>{event.antar}</td>
                      </tr>
                      <tr>
                        <td scope="row">समिती संपर्क</td>
                        <td>
                          <a
                            href={`tel:${event.committeeContact}`}
                            onClick={() =>
                              handleCallCommitteeContact(event.committeeContact)
                            }
                          >
                            <i class="fa-solid fa-phone"></i>{"  "}
                            {event.committeeContact}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">दिनांक</td>
                        <td>{formatDate(event.date)}</td>
                      </tr>
                      <tr>
                        <td scope="row">वेळ</td>
                        <td>{formatTime(event.time)}</td>
                      </tr>
                    </tbody>
                  </table>

                  {/* Add additional fields as needed */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AgamiMaidane;
