import React, { useState } from 'react';
import '../Admin/Admin.css';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../image/bailgada logo (1) (1).png';

const Navbar = () => {
  const [isHovered, setIsHovered] = useState(false); 
  const navigate = useNavigate();


  const handleLogout = () => {
    localStorage.removeItem('isAdminAuthenticated');
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="container-fluid">
        <img src={logo} width={'60px'} height={'60px'} alt="Logo" />
        
       <Link to='/developer' style={{float:'left', marginLeft:'-105px', color:'white', textDecoration:'none'}}>developed by <i class="fa-solid fa-user"></i></Link>

        <button
          style={{
            width: '60px',
            fontSize: '11px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: isHovered ? '#D21404' : 'red',
            border: '1px solid white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
          }}
          onClick={handleLogout}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
