import React from 'react';
import img from '../image/developer.jpeg';
import Header from './Header';
import Footer from './Footer';

function DevelopedBy() {
  // Inline CSS styles
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '93vh', // Full viewport height
    textAlign: 'center',
    background: 'linear-gradient(to bottom right, #bfd6ee, #183f61)', // Gradient background
    padding: '20px', // Optional: Add some padding around the content
  };
  const containerStyle2 = {
    height: '28vh', 
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.8)', 
    backgroundColor:'rgba(255,255,255,0.2)',
    padding:'30px'
  };

  const imageStyle = {
    width: '200px', 
    height: '220px', 
    borderRadius: '50%',
    marginBottom: '30px', 
    marginTop: '-150px',
    border: '2px solid #DAA520',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 1)', // Box shadow
  };


  const textStyle = {
    fontSize: '18px',
    color: '#333',
  };

  const textStyle2 = {
    fontSize: '24px',
    color: 'brown', 
  };

  const textStyle3 = {
    fontSize: '16px',
    color: 'brown', 
  };

  return (
    <>
      <Header />
      <div style={containerStyle}> 
      <div  style={containerStyle2}>
        <div style={{position: 'relative'}}>
          <img 
            src={img} 
            alt="User"
            style={imageStyle}
          />
        </div>
        <div style={textStyle}>
          <u>Developed by</u><br/> 
          <span style={textStyle2}><b>अभिजीत चंद्रकांत मदने</b></span><br/>
          <span style={textStyle3}>
            पत्ता- नेवरी रोड हिंगणगादे <br/>
            ता. खानापूर  जि. सांगली 415311
          </span>
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DevelopedBy;
