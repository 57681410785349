import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Admin/Admin.css';

function Footer() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path ? 'active-icon' : '';

  return (
    <div>
      <footer className="footer fixed-bottom">
        <div className="row py-1 pb-2">
          <div className="col-4">
            <Link to={'/maidane9244' } className={isActive('/maidane9244')}>
              <i className={`fa-solid fa-house ${isActive('/maidane9244')}`}></i> <br />
              <span className={`${isActive('/maidane9244')}`}>मैदाने</span>
            </Link>
          </div>
          <div className={`col-4`}>
            <Link to={'/create9244-agami9344-maidan'} className={isActive('/create9244-agami9344-maidan')}>
              <i className={`fa-regular fa-flag ${isActive('/create9244-agami9344-maidan')}`}></i> <br />
              <span  className={`${isActive('/create9244-agami9344-maidan')}`}>आगामी मैदाने</span>
            </Link>
          </div>
          <div className="col-4">
            <Link to={'/create9244-newsletter9344'} className={isActive('/create9244-newsletter9344')}>
              <i className={`fa-regular fa-newspaper ${isActive('/create9244-newsletter9344')}`}></i><br />
              <span  className={`${isActive('/create9244-newsletter9344')}`}>महत्वपूर्ण माहिती</span>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
