import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Admin/Admin.css';

function Footer() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path ? 'active-icon' : '';

  return (
    <div>
      <footer className="footer fixed-bottom">
        <div className="row py-1 pb-2">
          <div className="col-4">
            <Link to={'/'} className={isActive('/')}>
              <i className={`fa-solid fa-house ${isActive('/')}`}></i> <br />
              <span className={`${isActive('/')}`}>मैदाने</span>
            </Link>
          </div>
          <div className="col-4">
            <Link to={'/agami-maidan'} className={isActive('/agami-maidan')}>
              <i className={`fa-regular fa-flag ${isActive('/agami-maidan')}`}></i> <br />
              <span className={`${isActive('/agami-maidan')}`}>आगामी मैदाने</span>
            </Link>
          </div>
          <div className="col-4">
            <Link to={'/newsletter'} className={isActive('/newsletter')}>
              <i className={`fa-regular fa-newspaper ${isActive('/newsletter')}`}></i><br />
              <span className={`${isActive('/newsletter')}`}>महत्वपूर्ण माहिती</span>
            </Link>
          </div>
          {/* <div className="col-3">
            <Link to={'/favorites'} className={isActive('/favorites')}>
              <i className={`fa-solid fa-star ${isActive('/favorites')}`}></i><br />
              <span className={`${isActive('/favorites')}`}>Favorites</span>
            </Link>
          </div> */}
        </div>
      </footer>
    </div>
  );
}

export default Footer;
