import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from "../firebase";
import Header from "./Header";
import Footer from "./Footer";
import "./Admin.css";

function CreateSemifinaleList() {
  const { id } = useParams();
  const [semifinalistList, setSemifinalistList] = useState([]);
  const [showSemifinalistList, setShowSemifinalistList] = useState(true);
  const [semifinalGats, setSemifinalGats] = useState([]);
  const [finaleList, setFinaleList] = useState([]);
  const [winnersList, setWinnersList] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [showInputBox, setShowInputBox] = useState(false);
  const [selectedGatIndex, setSelectedGatIndex] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [selectedFinaleMembers, setSelectedFinaleMembers] = useState([]);
  const [selectedFinaleMembers2, setFinaleList2] = useState([]);
  const [showFinaleInputBox, setShowFinaleInputBox] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [winnerSequence, setWinnerSequence] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [winnersList2, setSortedFinaleList] = useState([]);

  const isMemberSentToFinale = (gatName, memberName) => {
    return finaleList.some(
      (item) => item.gatName === gatName && item.memberName === memberName
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "agamiMaidane", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setSemifinalGats(data.semifinaleGat || []);
          setFinaleList(data.finaleList || []);
          setSemifinalistList(data.semifinalistList || []);
          setWinnersList(data.WinnersList || []);
          setSelectedFinaleMembers(data.selectedFinaleMembers2)
          setSortedFinaleList([...data.winnersList].sort((a, b) => a.sequence - b.sequence) || []);
          setSelectedMembers(data.selectedFinaleMembers || []);
          setFinaleList2(data.selectedFinaleMembers2 || []);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [id]);
  const handleMemberSelect = (index, selectedMember) => {
    setSelectedMembers((prevSelectedMembers) => {
      const updatedMembers = [...prevSelectedMembers];
      console.log("updatedMembers",updatedMembers);
      updatedMembers[index] = selectedMember;
      
      console.log("updatedMembers[index]",updatedMembers[index]);
      console.log("selectedMember",selectedMember);
      console.log("updatedMembers2",updatedMembers);
      return updatedMembers;
    });
  };
  const saveFinalSelectedMembers = async () => {
    try {
      const docRef = doc(db, "agamiMaidane", id);
      await updateDoc(docRef, {
        selectedFinaleMembers2: selectedMembers
      });
      alert("Selected members saved successfully!");
    } catch (error) {
      console.error("Error saving selected members:", error);
      alert("Error saving selected members.");
    }
  };

  const saveSelectedMembers = async () => {
    try {
      const docRef = doc(db, "agamiMaidane", id);
      await updateDoc(docRef, {
        selectedFinaleMembers: selectedMembers
      });
      alert("विडियो जतन केला");
    } catch (error) {
      console.error("Error saving selected members:", error);
      alert("Error saving selected members.");
    }
  };

  const getFilteredMembers = (index) => {
    const selectedUpToIndex = Array.isArray(selectedMembers) ? selectedMembers.slice(0, index) : [];
    return finaleList.filter((member) => !selectedUpToIndex.includes(member.memberName));
  };
  

  const handleShowSemifinalistList = async () => {
    try {
      const docRef = doc(db, "agamiMaidane", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setShowSemifinalistList(true);
        setShowInputBox(false);
        setSelectedGatIndex(null);
        setShowFinaleInputBox(false);
        setWinnerPage(false)
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const handleCreateGroup = async () => {
    if (newGroupName.trim() === "") {
      alert("Please enter a group name.");
      return;
    }

    try {
      const docRef = doc(db, "agamiMaidane", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const semifinaleGatArray = data.semifinaleGat || [];
        const updatedSemifinaleGat = [
          ...semifinaleGatArray,
          { gatName: newGroupName, members: [] },
        ];

        await updateDoc(docRef, { semifinaleGat: updatedSemifinaleGat });
        setSemifinalGats(updatedSemifinaleGat);
        setNewGroupName("");
        setSelectedGatIndex(null);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleUpdateGroup = async () => {
    if (editingGatName.trim() === "") {
      alert("Please enter a group name.");
      return;
    }

    try {
      const docRef = doc(db, "agamiMaidane", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const semifinaleGatArray = data.semifinaleGat || [];
        const updatedSemifinaleGat = semifinaleGatArray.map((group, index) =>
          index === editingGatIndex ? { ...group, gatName: editingGatName } : group
        );

        await updateDoc(docRef, { semifinaleGat: updatedSemifinaleGat });
        setSemifinalGats(updatedSemifinaleGat);
        setEditingGatIndex(null); // Clear editing state after updating
        setEditingGatName('');
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };


  const handleClickGatName = (index, gatName) => {
    setEditingGatIndex(index); // Set the index of the group being edited
    setEditingGatName(gatName); // Set the name of the group being edited
  };

  const toggleGatMembers = (index) => {
    if (selectedGatIndex === index) {
      setSelectedGatIndex(null);
    } else {
      setSelectedGatIndex(index);
    }
  };

 
  const handleMemberSelection = (index, memberName) => {
    const updatedSelectedMembers = [...selectedMembers];
    if (!updatedSelectedMembers[index]) {
      updatedSelectedMembers[index] = [];
    }
    if (updatedSelectedMembers[index].includes(memberName)) {
      updatedSelectedMembers[index] = updatedSelectedMembers[index].filter(
        (name) => name !== memberName
      );
    } else {
      updatedSelectedMembers[index].push(memberName);
    }
    setSelectedMembers(updatedSelectedMembers);
  };

  const handleSaveGroupMembers = async (index) => {
    try {
      const docRef = doc(db, "agamiMaidane", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const updatedSemifinaleGat = [...data.semifinaleGat];
        updatedSemifinaleGat[index].members = selectedMembers[index] || [];

        await updateDoc(docRef, { semifinaleGat: updatedSemifinaleGat });
        setSemifinalGats(updatedSemifinaleGat);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleDoubleClickToRemove = (index, memberName) => {
    const updatedSelectedMembers = { ...selectedMembers };

    if (updatedSelectedMembers[index]) {
      updatedSelectedMembers[index] = updatedSelectedMembers[index].filter(
        (name) => name !== memberName
      );

      setSelectedMembers(updatedSelectedMembers);
    }
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `videos/${id}/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    setVideoLink(downloadURL);

    const docRef = doc(db, "agamiMaidane", id);
    await updateDoc(docRef, { videoLink: downloadURL });
  };
  useEffect(() => {
    const storedSequence = JSON.parse(localStorage.getItem(`winnerSequence_${id}`)) || {};
    setWinnerSequence(storedSequence);
  }, [id]);

  const handleSaveWinnersSequence = async () => {
    try {
      const docRef = doc(db, "agamiMaidane", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const updatedWinnersList = [...finaleList];

        updatedWinnersList.forEach((winner) => {
          if (winnerSequence[winner.memberName]) {
            winner.sequence = winnerSequence[winner.memberName];
          }
        });

        updatedWinnersList.sort((a, b) => a.sequence - b.sequence);

        await updateDoc(docRef, { winnersList: updatedWinnersList });

        setFinaleList(updatedWinnersList);
alert("विजेते जतन केले.")
        console.log("Winners list updated successfully!");
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleChangeSequence = (memberName, value) => {
    setWinnerSequence((prev) => ({
      ...prev,
      [memberName]: value,
    }));
  };

  const handleSendToFinale = async (index, memberName) => {
    try {
      const docRef = doc(db, "agamiMaidane", id);
      console.log("Attempting to access document:", docRef.path);
  
      let updatedFinaleList = [];
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        updatedFinaleList = [...(data.finaleList || [])];
        console.log("Document data fetched:", data);
      } else {
        console.log("Document does not exist. Creating with empty finale list.");
        await setDoc(docRef, { finaleList: [] });
      }
  
      const memberExists = updatedFinaleList.some(
        (item) => item.gatName === semifinalGats[index].gatName && item.memberName === memberName
      );
  
      if (!memberExists) {
        updatedFinaleList.push({
          gatName: semifinalGats[index].gatName,
          memberName: memberName,
        });
      }
  
      console.log("Updated finale list:", updatedFinaleList);
  
      await setDoc(docRef, { finaleList: updatedFinaleList }, { merge: true });
      console.log("Document successfully updated");
  
      setFinaleList(updatedFinaleList);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };
  
  
  
  const [editingGatIndex, setEditingGatIndex] = useState(null); // Track the index of the editing group
  const [editingGatName, setEditingGatName] = useState(''); // Track the name of the editing group

  
  
  const handleShowFinalMembers = () => {
    setShowFinaleInputBox(true);
    setShowSemifinalistList(false);
    setShowInputBox(false);
    setWinnerPage(false)
  };
  const [winnerPage, setWinnerPage] = useState(false);

  const openWinnerFunction = () => {
    setWinnerPage(true);
    setShowFinaleInputBox(false);
    setShowSemifinalistList(false);
    setShowInputBox(false);
  };

  const filteredSemifinalistList = semifinalistList.filter((member) =>
    member.gatName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.memberName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredfinaleList = finaleList.filter((member) =>
    member.gatName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.memberName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredsemifinalGats = semifinalGats.filter((group) => {
    const memberNames = group.members.join(' '); 
    return (
      group.gatName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      memberNames.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) => 
        part.toLowerCase() === searchTerm.toLowerCase() ? <span key={index} style={{ backgroundColor: '#FFDB58' }}>{part}</span> : part
    );
};


  return (
    <div>
      <Header />
      <div className="container">
        <h1 className="pageHeading">उत्तीर्ण खेळाडू व्यवस्थापन</h1>

        <div className="btn-group mb-3" role="group">
          <button
            className="btn btn-primary"
            onClick={handleShowSemifinalistList}
          >
            सेमी फायनल
          </button>

          <button
            className="btn btn-primary"
            onClick={() => {
              setShowInputBox(true);
              setShowSemifinalistList(false);
              setShowFinaleInputBox(false)
              setWinnerPage(false)
              setSelectedGatIndex(null);
            }}
          >
            सेमी गट
          </button>
          <button className="btn btn-primary" onClick={handleShowFinalMembers}>
            फायनल गट
          </button>

          <button className="btn btn-primary" onClick={openWinnerFunction}>
            निकाल
          </button>
        </div>
        <div className="text-end">
          <input
            type="text"
            placeholder="येथे शोधा"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {showFinaleInputBox && (
  <div className="table-responsive">
    <table className="table table-bordered mt-3">
      <thead>
        <tr>
          <th>फायनल गट</th>
        </tr>
      </thead>
      <tbody>
        {finaleList.map((selectedMember, index) => (
          <tr key={index}>
            <td>
              <b>फायनल तास {index + 1}: </b>
              <select className="ms-2"
                value={selectedMembers[index]}
                onChange={(e) => handleMemberSelect(index, e.target.value)}
              >
                <option value="">सिलेक्ट करा</option>
                {getFilteredMembers(index).map((member, memberIndex) => (
                  <option key={memberIndex} value={member.memberName}>
                  {highlightText(member.memberName, searchTerm)}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <button onClick={saveFinalSelectedMembers} className="btn btn-primary mt-2 mb-4">फायनल तास जतन करा</button>
    <div className="mb-5">
      {Array.isArray(selectedFinaleMembers) && selectedFinaleMembers.length > 0 ? (
        selectedFinaleMembers
          .map((member, index) => ({
            member,
            originalIndex: index + 1 // Original index is index + 1 to match "फायनल तास"
          }))
          .filter(({ member }) => member.toLowerCase().includes(searchTerm.toLowerCase()))
          .map(({ member, originalIndex }) => (
            <div
              key={member}
              className="gat-card mb-3"
              style={{
                backgroundColor: "#a1ecfe",
                border: "1px solid #dee2e6",
                borderRadius: "0.25rem",
              }}
            >
              <table className="table table-sm table-striped table-bordered mb-0">
                <tbody>
                  <tr>
                    <td><b>फायनल तास {originalIndex} -</b> {highlightText(member, searchTerm)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
      ) : (
        <p className="text-center">अंतिम फेरीची यादी तयार नाही.</p>
      )}
    </div>
  </div>
)}

        {winnerPage && (
          <>
            <div className="table-responsive">
              <h4 className="mt-2">विजेत्यांची निवड करा</h4>
              <input
                type="file"
                placeholder="व्हिडिओ लिंक प्रविष्ट करा"
                accept="image/*,video/*"
                onChange={handleVideoUpload}
              />
              <button onClick={saveSelectedMembers} className="btn btn-primary mt-3">विडियो जतन करा</button>
              {videoLink && (
                <div>
                  <h4>Uploaded Video:</h4>
                  <video width="300" height={'300px'} controls>
                    <source src={videoLink} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>नाव</th>
                    <th>क्रम</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredfinaleList.map((member, index) => (
                    <tr key={member.memberName}>
                      <td>{highlightText(member.memberName, searchTerm)}</td>
                      <td>
                        <input
                          type="number"
                          min="1"
                          value={winnerSequence[member.memberName] || ""}
                          onChange={(e) =>
                            handleChangeSequence(member.memberName, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="btn btn-primary mt-3"
                onClick={handleSaveWinnersSequence}
              >
                क्रम जतन करा
              </button>
            </div>
            <div className='table-responsive mb-5'>
            {winnersList2.length > 0 ? (
  winnersList2
    .map((finale, originalIndex) => ({
      finale,
      originalIndex: originalIndex + 1 // Original index is originalIndex + 1 to match "विजेता क्र."
    }))
    .filter(({ finale }) => finale.memberName.toLowerCase().includes(searchTerm.toLowerCase()))
    .map(({ finale, originalIndex }) => (
      <div
        key={finale.memberName} // Using memberName as key assuming it is unique
        className="gat-card mb-1"
        style={{
          backgroundColor: "#a1ecfe",
          border: "1px solid #dee2e6",
          borderRadius: "0.25rem",
        }}
      >
        <table className="table table-sm table-striped table-bordered mb-0">
          <tbody>
            <tr>
              <td><b>विजेता क्र. {originalIndex} - </b>{highlightText(finale.memberName, searchTerm)} </td>
            </tr>
          </tbody>
        </table>
      </div>
    ))
) : (
  <p className="text-center">कोणतीही माहिती उपलब्ध नाही.</p>
)}

            </div>
          </>
        )}
        {showSemifinalistList && (
          <div className="table-responsive">
            <h4 className="mb-3 mt-2">सेमी फायनल</h4>
            {filteredSemifinalistList.length > 0 ? (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>गट</th>
                    <th> नाव</th>
                  </tr>
                </thead>
                <tbody>
                {filteredSemifinalistList.map((member, index) => (
    <tr key={index}>
        <td>{member.gatName}</td>
        <td>
            तास {member.positionNumber} - {highlightText(member.memberName, searchTerm)}
        </td>
    </tr>
))}

                </tbody>
              </table>
            ) : (
              <p>सदस्य उपलब्ध नाहीत.</p>
            )}
          </div>
        )}
      {showInputBox && (
        <div>
          <h5 className="mt-3">
            {editingGatIndex !== null ? "संपादित करा गट" : "नवीन सेमी गट"}
          </h5>
          <input
            type="text"
            placeholder="गटाचे नाव प्रविष्ट करा"
            value={editingGatIndex !== null ? editingGatName : newGroupName}
            onChange={(e) =>
              editingGatIndex !== null
                ? setEditingGatName(e.target.value)
                : setNewGroupName(e.target.value)
            }
          />
          <button
            className="btn btn-primary mt-2"
            onClick={editingGatIndex !== null ? handleUpdateGroup : handleCreateGroup}
          >
            {editingGatIndex !== null ? "बदला" : "बनवा"}
          </button>
        </div>
      )}

           {showInputBox && (
          <>
            <h5 className="mt-5 ">सेमी गट सूची</h5>
            <div className="table-responsive horizontal-scroll-wrapper pb-5">
              <table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead>
                  <tr>
                    <th className="text-start">गट</th>
                    <th className="text-start">निवडा</th>
                    <th className="text-start">निवडलेले सदस्य</th>
                    <th className="text-start">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredsemifinalGats.map((group, index) => (
                    <tr key={index}>
                    <td
  className="text-start"
  onClick={() => {
    toggleGatMembers(index); // Call toggleGatMembers
    handleClickGatName(index, group.gatName); // Call handleClickGatName
  }}
  style={{ cursor: 'pointer' }}
>
  <b style={{ fontSize: '14px' }}> {group.gatName} </b>
</td>

                      {selectedGatIndex === index && (
                        <>
                          <td className="text-start">
                            <ul>
                              {semifinalistList
                                .filter((member) => {
                                  const selectedNames = Object.values(selectedMembers).flat();
                                  return !selectedNames.includes(member.memberName);
                                })
                                .map((member, mIndex) => (
                                  <li key={mIndex} className="list-unstyled text-start">
                                    <input
                                      className="me-2"
                                      type="checkbox"
                                      id={`${group.gatName}-${member.memberName}`}
                                      checked={
                                        selectedMembers[index] &&
                                        selectedMembers[index].includes(member.memberName)
                                      }
                                      onChange={() =>
                                        handleMemberSelection(index, member.memberName)
                                      }
                                    />
                                    <label htmlFor={`${group.gatName}-${member.memberName}`}>
                                    {highlightText(member.memberName, searchTerm)}
                                    </label>
                                  </li>
                                ))}
                            </ul>
                          </td>
                          <td className="text-start">
                            <ul>
                              {group.members.map((member, mIndex) => (
                                <li
                                  className="list-unstyled text-start"
                                  key={mIndex}
                                  onDoubleClick={() => handleDoubleClickToRemove(index, member)}
                                >
                                  <b>सेमी तास {mIndex + 1}: </b> {highlightText(member, searchTerm)}
                                  {isMemberSentToFinale(group.gatName, member) ? (
                                    <button
                                      disabled
                                      style={{
                                        background: 'green',
                                        fontSize: '11px',
                                        padding: '3px',
                                        paddingBottom: '0px',
                                      }}
                                    >
                                      पाठवले
                                    </button>
                                  ) : (
                                    <button
                                      style={{
                                        fontSize: '12px',
                                        paddingBottom: '0px',
                                      }}
                                      onClick={() => handleSendToFinale(index, member)}
                                    >
                                      पाठवा
                                    </button>
                                  )}
                                </li>
                              ))}
                              {selectedMembers[index]?.map((selectedMember, mIndex) => (
                                <li
                                  className="list-unstyled text-start"
                                  key={mIndex + semifinalGats[index].members.length}
                                  onDoubleClick={() =>
                                    handleDoubleClickToRemove(index, selectedMember)
                                  }
                                >
                                  <b> सेमी तास {
                                    semifinalGats[index].members.length + mIndex + 1
                                  }: </b> {selectedMember}
                                  {isMemberSentToFinale(
                                    semifinalGats[index].gatName,
                                    selectedMember
                                  ) ? (
                                    <button
                                      disabled
                                      style={{
                                        background: 'sky blue',
                                        fontSize: '12px',
                                        paddingBottom: '0px',
                                      }}
                                    >
                                      पाठवले
                                    </button>
                                  ) : (
                                    <button
                                      style={{
                                        fontSize: '11px',
                                        paddingBottom: '0px',
                                      }}
                                      onClick={() => handleSendToFinale(index, selectedMember)}
                                    >
                                      पाठवा
                                    </button>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td className="text-start">
                            <button
                              style={{ fontSize: '12px' }}
                              className="btn btn-success px-1 py-0 mt-3"
                              onClick={() => handleSaveGroupMembers(index)}
                            >
                              save
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
export default CreateSemifinaleList;
