import React, { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore'; // Firestore imports for querying
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { db } from '../firebase';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to right, #d0e3ff, #fff9f0);
`;

const Row = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px;
`;

const Col = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  font-weight: '900';
  margin-bottom: 20px;
  color: #007bff;
  font-family: 'Roboto', sans-serif;
`;

const Label = styled.label`
  font-weight: bold;
  color: #343a40;
  font-family: 'Roboto', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorText = styled.div`
  text-align: center;
  margin-top: 10px;
  color: red;
  font-family: 'Roboto', sans-serif;
`;

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null); // State to manage login errors
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Query Firestore to check if email and password match for admins
      const adminRef = collection(db, 'adminbvcregistertyu');
      const adminQuery = query(adminRef, where('email', '==', formData.email), where('password', '==', formData.password));
      const adminSnapshot = await getDocs(adminQuery);

      if (!adminSnapshot.empty) {
        // Admin logged in successfully
        localStorage.setItem('isAdminAuthenticated', 'true'); // Set authentication status
        alert("Admin logged in successfully!");
        navigate('/maidane9244'); // Redirect to admin page
        return;
      } else {
        setError("Invalid credentials");
      }
    } catch (error) {
      console.error("Error logging in user: ", error);
      setError("Error logging in");
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Title>Admin Login</Title>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Label htmlFor="formEmail">Email address</Label>
              <Input
                type="email"
                id="formEmail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
              />
            </div>

            <div className="mb-3">
              <Label htmlFor="formPassword">Password</Label>
              <Input
                type="password"
                id="formPassword"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
              />
            </div>

            <Button type="submit">Login</Button>
            {error && <ErrorText>{error}</ErrorText>}
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
