import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './Maidaneuser.css';

function Favorites() {
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const savedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(savedFavorites);
  }, []);

  const handleShowGat = (id) => {
    navigate(`/show-gats/${id}`);
  };

  const removeFavorite = (id) => {
    const updatedFavorites = favorites.filter(event => event.id !== id);
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };

  return (
    <div>
      <Header />
      <div className="container mt-2 mb-5 pb-5">
        <h3 className='pageHeading'>Favorites</h3>
        <div className="row">
          {favorites.map((event) => (
            <div key={event.id} className="col-md-4 mb-4">
              <div className={`maindan-card ${event.isLive ? 'live-card' : ''}`}>
                <div className="card-body">
                  <div className='card-inline-elements'>
                    <h5 className="card-title text-success">{event.place}</h5>
                    <button className='set-team-btn' onClick={() => handleShowGat(event.id)}>गट पहा</button>
                  </div>
                  <table className="table border-dark mb-0">
                    <tbody>
                      <tr>
                        <td>ठिकाण</td>
                        <td>
                          {event.place}
                        </td>
                      </tr>
                      <tr>
                        <td>समालोचक</td>
                        <td>{event.samalochak}</td>
                      </tr>
                      <tr>
                        <td scope="row">झेंडापंच</td>
                        <td>{event.zendapanch}</td>
                      </tr>
                      <tr>
                        <td scope="row">अंतर</td>
                        <td>{event.antar}</td>
                      </tr>
                      <tr>
                        <td scope="row">समिती संपर्क</td>
                        <td>
                          {event.committeeContact}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">दिनांक</td>
                        <td>{event.date}</td>
                      </tr>
                      <tr>
                        <td scope="row">वेळ</td>
                        <td>{event.time}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button className='remove-favorite-btn' onClick={() => removeFavorite(event.id)}>Remove from Favorites</button>
                </div>
                {event.isLive && (
                  <div className="live-symbol">
                    <div>LIVE</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Favorites;
