import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, storage } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Header from "./Header";
import Footer from "./Footer";


function ShowGats() {
  const { id } = useParams();
  const [gats, setGats] = useState([]);
  const [semifinalGat, setSemifinalGat] = useState([]);
  const [selectedFinaleMembers2, setFinaleList] = useState([]);
  const [winnersList, setSortedFinaleList] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [placeName, setPlaceName] = useState("");
  const [selectedSection, setSelectedSection] = useState("gats");
  const [searchTerm, setSearchTerm] = useState("");
  const [showMembers, setShowMembers] = useState({});
  const [semifinalistList, setSemifinalistList] = useState([]);
  const [finaleList, setGetFinaleList] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "agamiMaidane", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setGats(data.gatKramank || []);
        setSemifinalGat(data.semifinaleGat || []);
        setSemifinalistList(data.semifinalistList || []);
        setFinaleList(data.selectedFinaleMembers2 || []);
        setGetFinaleList(data.finaleList || []);
        setSortedFinaleList(Array.isArray(data.winnersList) ? [...data.winnersList].sort((a, b) => a.sequence - b.sequence) : []);
        setVideoLink(data.videoLink || "");
        setPlaceName(data.place || "");
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, [id]);

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleShowMembersToggle = (gatName) => {
    setShowMembers((prevState) => (prevState === gatName ? "" : gatName));
};

  const filterGats = (gatList) => {
    return gatList.filter((gat) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return (
        (gat.name && gat.name.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (gat.members && gat.members.some((member) => member && member.toLowerCase().includes(lowerCaseSearchTerm)))
      );
    });
  };
  const isMemberInSemifinal = (member) => {
    return semifinalistList.some(
      (semifinalist) => semifinalist.memberName === member
    );
  };
 
  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) => 
        part.toLowerCase() === searchTerm.toLowerCase() ? <span key={index} style={{ backgroundColor: '#FFDB58' }}>{part}</span> : part
    );
};

  return (
    <div>
    <Header />
  
    <div className="container mt-5 mb-5 pb-4">
      <h1 className="text-center pageHeading mb-4">मैदाने: {placeName}</h1>
      <div className="d-flex justify-content-center mb-4">
        <button
          className="btn btn-light mx-2"
          style={{
            backgroundColor: selectedSection === "gats" ? "#007bff" : "#e9ecef",
            color: selectedSection === "gats" ? "#fff" : "#000",
          }}
          onClick={() => handleSectionChange("gats")}
        >
          गट
        </button>
        <button
          className="btn btn-light mx-2"
          style={{
            backgroundColor: selectedSection === "semifinals" ? "#007bff" : "#e9ecef",
            color: selectedSection === "semifinals" ? "#fff" : "#000",
          }}
          onClick={() => handleSectionChange("semifinals")}
        >
          सेमी
        </button>
        <button
          className="btn btn-light mx-2"
          style={{
            backgroundColor: selectedSection === "finale" ? "#007bff" : "#e9ecef",
            color: selectedSection === "finale" ? "#fff" : "#000",
          }}
          onClick={() => handleSectionChange("finale")}
        >
          फायनल
        </button>
        <button
          className="btn btn-light mx-2"
          style={{
            backgroundColor: selectedSection === "sortedFinale" ? "#007bff" : "#e9ecef",
            color: selectedSection === "sortedFinale" ? "#fff" : "#000",
          }}
          onClick={() => handleSectionChange("sortedFinale")}
        >
          निकाल
        </button>
      </div>
  
      <div className="row justify-content-center mb-4">
        <div className="col-md-8">
          <input
            type="text"
            className="form-control"
            placeholder="गट नाव किंवा सदस्य नाव शोधा"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
  
      <div className="row justify-content-center">
        <div className="col-md-8">
          {selectedSection === "gats" && (
            <div>
              <h4 className="text-center mb-3 pageHeading">गट </h4>
              {filterGats(gats).map((gat) => (
                <div
                  key={gat.srNo}
                  className="gat-card p-1 mb-3"
                  style={{
                    backgroundColor: "#a1ecfe",
                    border: "2px solid #dee2e6",
                    borderRadius: "0.25rem",
                  }}
                  onClick={() => handleShowMembersToggle(gat.name)}
                >
                  <h5 className="text-start">
                    {gat.name} <i className="fa-solid fa-caret-down ms-3"></i>
                  </h5>
                  {showMembers === gat.name && (
                    <table className="table table-sm table-striped table-bordered">
                    <tbody>
                          {gat.members.map((member, index) => {
                            const isInSemifinal = isMemberInSemifinal(member);
                            const isInFinal = semifinalistList.some(
                              (semifinalist) => semifinalist.memberName === member
                            );
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    backgroundColor: isInSemifinal ? "#00ab6b" : "transparent",
                                    color: isInSemifinal ? "white" : "black",
                                  }}
                                >
                                  <b> तास {index + 1} - </b> {highlightText(member, searchTerm)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                    </table>
                  )}
                </div>
              ))}
            </div>
          )}
  
          {selectedSection === "semifinals" && (
            <div>
              <h4 className="text-center mb-3 pageHeading">सेमी </h4>
              {filterGats(semifinalGat).map((gat, index) => (
                <div
                  key={index}
                  className="gat-card p-1 mb-3"
                  style={{
                    backgroundColor: "#a1ecfe",
                    border: "2px solid #dee2e6",
                    borderRadius: "0.25rem",
                  }}
                  onClick={() => handleShowMembersToggle(gat.gatName)}
                >
                  <h5 className="text-start">
                    {gat.gatName} <i className="fa-solid fa-caret-down ms-3"></i>
                  </h5>
                  {showMembers === gat.gatName && (
                    <table className="table table-sm table-striped table-bordered">
                    <tbody>
              {gat.members.map((member, index) => {
                const isInFinaleList = finaleList.some(item => item.memberName === member);

                return (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: isInFinaleList ? "#00ab6b" : "transparent",
                        color: isInFinaleList ? "white" : "black",
                      }}
                    >
                      <b> तास {index + 1} - </b>  {highlightText(member, searchTerm)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
                    </table>
                  )}
                </div>
              ))}
            </div>
          )}
  
          {selectedSection === "finale" && (
            <div>
              <h4 className="text-center mb-3 pageHeading">फायनल</h4>
              {selectedFinaleMembers2.length > 0 ? (
                selectedFinaleMembers2
                  .map((member, index) => ({
                    member,
                    originalIndex: index + 1, // Original index is index + 1 to match "फायनल तास"
                  }))
                  .filter(({ member }) => member.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map(({ member, originalIndex }) => (
                    <div
                      key={member}
                      className="gat-card mb-3"
                      style={{
                        backgroundColor: "#a1ecfe",
                        border: "1px solid #dee2e6",
                        borderRadius: "0.25rem",
                      }}
                    >
                      <table className="table table-sm table-striped table-bordered mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <b> फायनल तास {originalIndex} -</b>  {highlightText(member, searchTerm)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))
              ) : (
                <p className="text-center">अंतिम फेरीची यादी तयार नाही.</p>
              )}
            </div>
          )}
  
          {selectedSection === "sortedFinale" && (
            <div>
              <h6 className="text-center mb-3 pageHeading">विजेता क्रमांक</h6>
  
              {videoLink && (
                <div className="mb-3">
                  <h6>विडियो </h6>
                  <video width="300" controls>
                    <source src={videoLink} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
  
              {winnersList && winnersList.length > 0 ? (
                winnersList
                  .map((finale, originalIndex) => ({
                    finale,
                    originalIndex: originalIndex + 1, // Original index is originalIndex + 1 to match "विजेता क्र."
                  }))
                  .filter(({ finale }) => finale.memberName.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map(({ finale, originalIndex }) => (
                    <div
                      key={finale.memberName}
                      className="gat-card mb-3"
                      style={{
                        backgroundColor: "#a1ecfe",
                        border: "1px solid #dee2e6",
                        borderRadius: "0.25rem",
                      }}
                    >
                      <table className="table table-sm table-striped table-bordered mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <b>💐 विजेता क्र. {originalIndex} - </b> {highlightText(finale.memberName, searchTerm)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))
              ) : (
                <p className="text-center">अद्याप कोणताही विजेता नाही.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}
export default ShowGats;