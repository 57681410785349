import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import Header from './Header';
import Footer from './Footer';
import './Admin.css';

function Maidane() {
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'agamiMaidane'), (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const sortedMessages = fetchedMessages.map(message => ({
        ...message,
        liveStartTime: message.liveStartTime || message.time,
        liveStopTime: message.liveStopTime || null
      }));
      setMessages(sortedMessages);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDateTime = new Date();
      setMessages(prevMessages => {
        const updatedMessages = prevMessages.map(message => {
          const liveStartDateTime = new Date(`${message.date}T${message.liveStartTime}:00.000+05:30`);
          const liveStopDateTime = message.liveStopTime ? new Date(`${message.date}T${message.liveStopTime}:00.000+05:30`) : null;

          const dateDue = isDateDue(message.date);

          if (dateDue) {
            if (message.isLive) {
              updateDoc(doc(db, 'agamiMaidane', message.id), { isLive: false })
                .catch(error => console.error('Error updating live status:', error));
            }
            return { ...message, isLive: false };
          }

          const isLive = currentDateTime >= liveStartDateTime && (!liveStopDateTime || currentDateTime < liveStopDateTime);

          if (isLive !== message.isLive) {
            updateDoc(doc(db, 'agamiMaidane', message.id), { isLive })
              .catch(error => console.error('Error updating live status:', error));
          }

          return { ...message, isLive };
        });

        const liveMessages = updatedMessages.filter(message => message.isLive);
        const upcomingMessages = updatedMessages.filter(message => !isDateDue(message.date) && !message.isLive);
        const expiredMessages = updatedMessages.filter(message => isDateDue(message.date));

        return [...liveMessages, ...upcomingMessages, ...expiredMessages];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleLiveStatusChange = async (id, isLive) => {
    try {
      if (isLive) {
        await updateDoc(doc(db, 'agamiMaidane', id), { isLive, liveStopTime: null });
      } else {
        const currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
        await updateDoc(doc(db, 'agamiMaidane', id), { isLive, liveStopTime: currentDateTime });
      }
      setMessages(prevMessages => {
        const updatedMessages = prevMessages.map(message =>
          message.id === id ? { ...message, isLive, liveStopTime: isLive ? null : message.liveStopTime } : message
        );

        const liveMessages = updatedMessages.filter(message => message.isLive);
        const upcomingMessages = updatedMessages.filter(message => !isDateDue(message.date) && !message.isLive);
        const expiredMessages = updatedMessages.filter(message => isDateDue(message.date));

        return [...liveMessages, ...upcomingMessages, ...expiredMessages];
      });
    } catch (error) {
      console.error('Error updating live status:', error);
    }
  };

  const handleLiveStartTimeChange = async (id, liveStartTime) => {
    try {
      await updateDoc(doc(db, 'agamiMaidane', id), { liveStartTime });
      setMessages(prevMessages => {
        const updatedMessages = prevMessages.map(message => ({
          ...message,
          liveStartTime: message.id === id ? liveStartTime : message.liveStartTime || message.time
        }));

        const liveMessages = updatedMessages.filter(message => message.isLive);
        const upcomingMessages = updatedMessages.filter(message => !isDateDue(message.date) && !message.isLive);
        const expiredMessages = updatedMessages.filter(message => isDateDue(message.date));

        return [...liveMessages, ...upcomingMessages, ...expiredMessages];
      });
    } catch (error) {
      console.error('Error updating live start time:', error);
    }
  };

  const formatTime = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}:00.000+05:30`);
    return time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  };

  const isDateDue = (date) => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const eventDate = new Date(date).setHours(0, 0, 0, 0);
    return currentDate > eventDate;
  };

  const goToCreateTeamPage = (id) => {
    navigate(`/createGat9244/${id}`);
  };

  const goToSemiFinalList = (id) => {
    navigate(`/create9244-semi9344-final-list/${id}`);
  };

  const filteredMessages = messages.filter(message => 
    message.place.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className='maidane-bg'>
      <Header />
      <div className="container mt-2 mb-5 pb-5">
        <h3 className='pageHeading'>मैदाने</h3>
        <div className="mb-3 maidanesearch-bar">
          <input
            type="text"
            className="form-control"
            placeholder="मैदाने शोधा..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>


        <div className="row">
          {filteredMessages.map((message) => {
            const dateDue = isDateDue(message.date);
            return (
              <div className='col-md-4 mb-4' key={message.id}>
                <div className={`card ${message.isLive ? 'live-card' : ''}`}>
                  <div className="card-body">
                    <div className='maidane-card-head'>
                      <div className="card-inline-elements">
                        <h5 className="card-title">{message.place}</h5>
                        <button className='set-team-btn' onClick={() => goToCreateTeamPage(message.id)}>गट तयार करा</button>
                      </div>
                      <p className="card-text"><strong>समालोचक:</strong> {message.samalochak} 
                      <a href={message.maplocation} target="_blank" rel="noopener noreferrer" className='map-img'></a>
                      </p>
                      <p className="card-text"><strong>झेंडापंच:</strong> {message.zendapanch}</p>
                      <p className="card-text"><strong>अंतर:</strong> {message.antar}</p>
                      <p className="card-text"><strong>समिती संपर्क:</strong> {message.committeeContact}</p>
                      <p className="card-text"><strong>दिनांक:</strong> {formatDate(message.date)}</p>
                      <p className="card-text"><strong>वेळ:</strong> {formatTime(message.time)}</p>
                    </div>
                    <div className='live-area p-2'>
                      <div className="mt-3">
                        <label htmlFor={`liveStartTime-${message.id}`} className="form-label">Set Live Start Time</label>
                        <input
                          type="time"
                          className="form-control"
                          id={`liveStartTime-${message.id}`}
                          value={message.liveStartTime || ''}
                          onChange={(e) => handleLiveStartTimeChange(message.id, e.target.value)}
                          disabled={dateDue}
                        />
                      </div>
                      {/* <div className="mt-3">
                        <label htmlFor={`liveStopTime-${message.id}`} className="form-label">Set Live Stop Time</label>
                        <input
                          type="time"
                          className="form-control"
                          id={`liveStopTime-${message.id}`}
                          value={message.liveStopTime || ''}
                          onChange={(e) => handleLiveStopTimeChange(message.id, e.target.value)}
                          disabled={dateDue}
                        />
                      </div> */}
                      <div className="mt-3">
                         <button
                          className={`btn ${message.isLive ? 'livestart-button' : 'liveend-button'}`}
                          onClick={() => handleLiveStatusChange(message.id, !message.isLive)}
                          disabled={dateDue}
                        >
                          {message.isLive ? 'Stop Live' : 'Start Live'}
                        </button>
                        <button
                          className="show-finale-btn p-1 float-end"
                          onClick={() => goToSemiFinalList(message.id)}
                        >
                          पुढील फेरी <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Maidane;
