import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './Maidaneuser.css'; // Import the CSS file
import lg from '../image/lg.jpeg';
import { FaHeart, FaRegHeart } from 'react-icons/fa'; // Import heart icons

function Maidaneuser() {
  const [events, setEvents] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'agamiMaidane'), (snapshot) => {
      const fetchedEvents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      fetchedEvents.sort((a, b) => (b.isLive ? 1 : 0) - (a.isLive ? 1 : 0));
      setEvents(fetchedEvents);
      setFilteredEvents(fetchedEvents);
    });

    // Load favorites from local storage
    const savedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(savedFavorites);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = events.filter(event => event.place.toLowerCase().includes(lowercasedQuery));
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(events);
    }
  }, [searchQuery, events]);

  const formatTime = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}`);
    return time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  };

  const handleShowGat = (id) => {
    navigate(`/show-gats/${id}`);
  };

  const toggleFavorite = (event) => {
    let updatedFavorites;
    if (favorites.some(fav => fav.id === event.id)) {
      updatedFavorites = favorites.filter(fav => fav.id !== event.id);
    } else {
      updatedFavorites = [...favorites, event];
    }
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };

  const isFavorite = (id) => {
    return favorites.some(fav => fav.id === id);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <div>
      <Header />
      <div className="container mt-2 mb-5 pb-5">
        <h3 className='pageHeading'>मैदाने</h3>
        <div className="maidanesearch-bar mb-4">
          <input 
            type="text" 
            placeholder="मैदाने शोधा..." 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="row">
          {filteredEvents.map((event) => (
            <div key={event.id} className="col-md-4 mb-4">
              <div className={`maindan-card ${event.isLive ? 'live-card' : ''}`}>
                <div className="card-body">
                  <div className='card-inline-elements'>
                    <h5 className="card-title text-success">{event.place}</h5>
                    {/* <div className='favorite-icon' onClick={() => toggleFavorite(event)}>
                      {isFavorite(event.id) ? <FaHeart color="red" /> : <FaRegHeart />}
                    </div> */}
                  </div>
                  <table className="table border-dark mb-0">
                    <tbody>
                      <tr>
                        <td>ठिकाण</td>
                        <td>
                          {event.place}
                          <a href={event.maplocation} target="_blank" rel="noopener noreferrer" className='map-img'>
                            <img
                              src={lg}
                              alt="winner"
                              style={{ width: "40px", height: "40px", right: "0", float: "right" }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>समालोचक</td>
                        <td>{event.samalochak}</td>
                      </tr>
                      <tr>
                        <td scope="row">झेंडापंच</td>
                        <td>{event.zendapanch}</td>
                      </tr>
                      <tr>
                        <td scope="row">अंतर</td>
                        <td>{event.antar}</td>
                      </tr>
                      <tr>
                        <td scope="row">समिती संपर्क</td>
                        <td>{event.committeeContact}</td>
                      </tr>
                      <tr>
                        <td scope="row">दिनांक</td>
                        <td>{formatDate(event.date)}</td>
                      </tr>
                      <tr>
                        <td scope="row">वेळ</td>
                        <td>{formatTime(event.time)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button className='set-team-btn' onClick={() => handleShowGat(event.id)}>गट पहा</button>
                </div>
                {event.isLive && (
                  <div className="live-symbol">
                    <div>LIVE</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Maidaneuser;
