import React from 'react';
import AdminLogin from './Admin/AdminLogin';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AgamiMaidane from './Admin/AgamiMaidane';
import AgamiMaidane2 from './User/AgamiMaidane';
import Maidane from './Admin/Maidane';
import Newsletter from './Admin/Newsletter';
import Newsletter1 from './User/Newsletter';
import CreateGat from './Admin/CreateGat';
import CreateSemifinaleList from './Admin/CreateSemifinaleList';
import ManageSemiFinaleTeam from './Admin/ManageSemiFinaleTeam';
import Maidaneuser from './User/Maidaneuser';
import ShowGats from './User/ShowGats';
import UserLogin from './User/UserLogin';
import Register from './User/Register';
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component
import Favorites from './User/Favorites';
import ProfilePage from './ProfilePage';
import DevelopedBy from './Admin/Developedby';
import DevelopedBy1 from './User/Developedby';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {/* <Route path='/' element={<UserLogin />} /> */}
        {/* <Route path='/userRegister' element={<Register />} /> */}


          {/* Public pages */}
          <Route path='/developer' element={<DevelopedBy />} />
          <Route path='/developer1' element={<DevelopedBy1 />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/favorites' element={<Favorites />} />
          <Route path='/login' element={<AdminLogin />} />
          <Route path='/' element={<Maidaneuser />} />
          <Route path='/agami-maidan' element={<AgamiMaidane2 />} />
          <Route path='/newsletter' element={<Newsletter1 />} />
          <Route path='/show-gats/:id' element={<ShowGats />} />

          {/* Protected admin pages */}
          <Route path="/maidane9244" element={<PrivateRoute><Maidane /></PrivateRoute>} />
          <Route path="/create9244-agami9344-maidan" element={<PrivateRoute><AgamiMaidane /></PrivateRoute>} />
          <Route path="/create9244-newsletter9344" element={<PrivateRoute><Newsletter /></PrivateRoute>} />
          <Route path="/createGat9244/:id" element={<PrivateRoute><CreateGat /></PrivateRoute>} />
          <Route path="/create9244-semi9344-final-list/:id" element={<PrivateRoute><CreateSemifinaleList /></PrivateRoute>} />
          <Route path="/manageGat9244/:id/:gatIndex" element={<PrivateRoute><ManageSemiFinaleTeam /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
