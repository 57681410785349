import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, deleteDoc, doc, setDoc, onSnapshot } from "firebase/firestore";
import Header from './Header';
import Footer from './Footer';
import AlertPage from './Alertpage';

function AgamiMaidane() {
  const [formData, setFormData] = useState({
    place: '',
    maplocation: '',
    samalochak: '',
    zendapanch: '',
    antar: '',
    committeeContact: '',
    date: '',
    time: '',
  });

  const [messages, setMessages] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIdToDelete, setCurrentIdToDelete] = useState(null);


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'agamiMaidane'), (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode && editId) {
        // Update existing document
        await updateDocument(editId, {
          place: formData.place,
          maplocation: formData.maplocation,
          samalochak: formData.samalochak,
          zendapanch: formData.zendapanch,
          antar: formData.antar,
          committeeContact: formData.committeeContact,
          date: formData.date,
          time: formData.time,
        });
      } else {
        // Add new document
        await addDoc(collection(db, 'agamiMaidane'), {
          place: formData.place,
          maplocation: formData.maplocation,
          samalochak: formData.samalochak,
          zendapanch: formData.zendapanch,
          antar: formData.antar,
          committeeContact: formData.committeeContact,
          date: formData.date,
          time: formData.time,
        });
      }

      alert('Agami Maidane submitted successfully!');
      setFormData({
        place: '',
        maplocation: '',
        samalochak: '',
        zendapanch: '',
        antar: '',
        committeeContact: '',
        date: '',
        time: '',
      });
      setEditMode(false);
      setEditId(null);
    } catch (error) {
      console.error('Error submitting Agami Maidane:', error);
      alert('Failed to submit Agami Maidane. Please try again.');
    }
  };

  const updateDocument = async (id, newData) => {
    try {
      const docRef = doc(db, 'agamiMaidane', id);
      await setDoc(docRef, newData, { merge: true });
      console.log('Document updated successfully!');
    } catch (error) {
      console.error('Error updating document:', error);
      alert('Failed to update document. Please try again.');
    }
  };

  const handleEdit = (message) => {
    setFormData({
      place: message.place,
      maplocation: message.maplocation,
      samalochak: message.samalochak,
      zendapanch: message.zendapanch,
      antar: message.antar,
      committeeContact: message.committeeContact,
      date: message.date,
      time: message.time,
    });
    setEditMode(true);
    setEditId(message.id);
  };

  const openModal = (id) => {
    setCurrentIdToDelete(id);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      if (currentIdToDelete) {
        await deleteDoc(doc(db, 'agamiMaidane', currentIdToDelete));
        console.log('Document deleted successfully!');
        setIsModalOpen(false);
        setCurrentIdToDelete(null);
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      alert('Failed to delete document. Please try again.');
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setCurrentIdToDelete(null);
  };
  // Function to format time in 12-hour format with AM/PM
  const formatTime = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}`);
    return time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  };
  // Helper function to format date in dd-mm-yyyy
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-indexed
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};


  return (
    <div>
      <Header />
      <div className="container mt-4">
        <h3 className='pageHeading'>आगामी मैदाने</h3>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-10">
            <div className="p-4 border rounded shadow">
              <form onSubmit={handleSubmit}>
                <div className="mb-3 row">
                  <label htmlFor="place" className="col-sm-3 col-form-label text-start">ठिकाण</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="place"
                      name="place"
                      value={formData.place}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="maplocation" className="col-sm-3 col-form-label text-start ">मॅप लोकेशन निवडा</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="maplocation"
                      name="maplocation"
                      value={formData.maplocation}
                      onChange={handleChange}
                      placeholder="गूगल मॅप वरुन ठिकाणाची लिंक कॉपी करून टाका. (eg. https://maps.app.goo.gl/dheWTcBZ6rRTeWJGA) "
                    />
                  </div>
                  <div className="col-md-2 col-6 mt-3 mt-md-0">  
                    <button className='p-1' style={{ backgroundColor: 'yellow', border: '1px solid red', borderRadius: '4px', transition: 'background-color 0.3s ease, border-color 0.3s ease' }}> 
                      <a 
                        href='https://www.google.com/maps/@16.8541892,74.6049057,14z?entry=ttu'
                        className="text-decoration-none text-danger"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        गूगल मॅप वर जा <i className="fa-solid fa-location-arrow"></i>
                      </a>
                    </button>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="samalochak" className="col-sm-3 col-form-label text-start">समालोचक</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="samalochak"
                      name="samalochak"
                      value={formData.samalochak}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="zendapanch" className="col-sm-3 col-form-label text-start">झेंडापंच</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="zendapanch"
                      name="zendapanch"
                      value={formData.zendapanch}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="antar" className="col-sm-3 col-form-label text-start">अंतर</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="antar"
                      name="antar"
                      value={formData.antar}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="committeeContact" className="col-sm-3 col-form-label text-start">समिती संपर्क</label>
                  <div className="col-sm-9">
                    <input
                      type="tel"
                      className="form-control w-100"
                      id="committeeContact"
                      name="committeeContact"
                      value={formData.committeeContact}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="date" className="col-sm-3 col-form-label text-start">दिनांक</label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="time" className="col-sm-3 col-form-label text-start">वेळ</label>
                  <div className="col-sm-9">
                    <input
                      type="time"
                      className="form-control"
                      id="time"
                      name="time"
                      value={formData.time}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">{editMode ? 'Update' : 'Submit'}</button>
              </form>
            </div>
          </div>
        </div>
        <div className="table-responsive mt-5 mb-4">
        <table className="table table-striped table-hover mb-5 table-auto" style={{ minWidth: '960px',
  fontSize:' 12px'}}>
  <thead className="thead-dark">
    <tr>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>ठिकाण</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>लोकेशन</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>समालोचक</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>झेंडापंच</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>अंतर</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>समिती संपर्क</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>दिनांक</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>वेळ</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>सुधारा</th>
      <th style={{wordBreak: 'break-word', whiteSpace:'normal'}}>काढा</th>
    </tr>
  </thead>
  <tbody>
    {messages.map((message) => (
      <tr key={message.id}>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>{message.place}</td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>
          {message.maplocation && (
            <div>
              <a
                href={message.maplocation}
                className="btn btn-danger mt-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-location-dot"></i>
              </a>
            </div>
          )}
        </td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>{message.samalochak}</td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>{message.zendapanch}</td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>{message.antar}</td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}><a href={`tel:${message.committeeContact}`}>{message.committeeContact}</a></td>
        <td> {formatDate(message.date)}</td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>{formatTime(message.time)}</td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>
          <button className="btn btn-primary bg-transparent text-primary" onClick={() => handleEdit(message)}><i  style={{fontSize:'14px'}} class="fa-solid fa-pen-to-square"></i></button>
        </td>
        <td style={{wordBreak: 'break-word', whiteSpace:'normal'}}>
        <button className="btn btn-danger btn-sm bg-transparent text-danger"  onClick={() => openModal(message.id)}><i  style={{fontSize:'12px'}} class="fa-solid fa-trash-can"></i></button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

        </div>
      </div>
      <AlertPage
        isOpen={isModalOpen}
        onConfirm={handleDelete}
        onCancel={handleModalCancel}
        message="Are you sure you want to delete this item?"
      />
      <Footer />
    </div>
  );
}

export default AgamiMaidane;
