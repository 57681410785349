// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDKt0asidm5aMvXIralaSfPnwwvF_NpeLU",
  authDomain: "maidanapp-9685c.firebaseapp.com",
  projectId: "maidanapp-9685c",
  storageBucket: "maidanapp-9685c.appspot.com",
  messagingSenderId: "611156034278",
  appId: "1:611156034278:web:7d4a8e4882c6b9349eb127",
  measurementId: "G-ZGE14PV2RF"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); 

export { db, storage, auth };
