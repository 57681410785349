import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../image/bailgada logo (1) (1).png';

const Header = () => {
  const [logoClickCount, setLogoClickCount] = useState(0);

  const handleLogoClick = () => {
    setLogoClickCount(logoClickCount + 1);
    if (logoClickCount + 1 === 4) {
      window.location.href = '/login'; 
    }
  };

  const linkStyle = {
    display: 'inline-block',
    padding: '4px 8px 1px 8px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: '2px solid #007bff',
    borderRadius: '5px',
    textDecoration: 'none',
    textAlign: 'center',
    transition: 'background-color 0.3s ease, border-color 0.3s ease',
  };

  const hoverStyle = {
    backgroundColor: '#0056b3',
    borderColor: '#0056b3',
  };

  return (
    <nav className="navbar">
      <div className="container-fluid">
        <img
          src={logo}
          width={'60px'}
          alt="Logo"
          onClick={handleLogoClick} 
          style={{ cursor: 'pointer' }}
        />
       
       {/* <Link to='/profile' style={{float: 'left', marginLeft:'-30%', color:'red'}}>Profile</Link> */}

       <Link to='/developer1' style={{float: 'left', marginLeft:'-50px', color:'white', textDecoration:'none'}}>developed by <i class="fa-solid fa-user"></i></Link>

        {/* {logoClickCount === 3 && !isAdminLoggedIn && ( */}
        <Link
        to="/login"
        className="login-link"
        style={linkStyle}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = hoverStyle.backgroundColor;
          e.target.style.borderColor = hoverStyle.borderColor;
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = linkStyle.backgroundColor;
          e.target.style.borderColor = linkStyle.borderColor;
        }}
      >
        Admin Login
      </Link>
        {/* )} */}
      </div>
    </nav>
  );
};

export default Header;
